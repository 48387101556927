import React from "react";
import PageFooter from "../components/PageFooter";
import { NavLink } from "react-router-dom";

function NotFound() {
  return (
    <>
      <div className="pt-10">
        <div className="container mx-auto px-4">
          <div className="md:p-8 mb-10 bg-cream rounded-md shadow-md">
            <h1 className="text-center pt-16 md:p-0">Routing Error!</h1>
            <div className="flex md:flex-row flex-col-reverse pb-10">
              <div className="w-full flex justify-center flex-col p-4 md:p-0">
                <h3 className="text-center">
                  The requested resource could not be found.
                </h3>
                <p className="text-center mb-4">
                  <em>
                    It has been moved or is no longer available on this server.
                  </em>
                </p>
                <NavLink
                  to="/"
                  className="w-fit mx-auto bg-brand hover:bg-brandlt text-cream hover:text-brand border-2 border-brand hover:border-brandlt font-bold py-2 px-4 rounded-full transition-all shadow-md mb-4"
                >
                  Home
                </NavLink>
                <NavLink
                  to="/contact"
                  className="w-fit mx-auto bg-brand hover:bg-brandlt text-cream hover:text-brand border-2 border-brand hover:border-brandlt font-bold py-2 px-4 rounded-full transition-all shadow-md"
                >
                  Contact
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PageFooter />
    </>
  );
}

export default NotFound;
