import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

function SuperNav() {
  return (
    <div className="w-full bg-brand p-2 flex justify-center fixed bottom-0 z-10 md:hidden">
      <nav>
        <ul className="flex mb-0">
          <li className="text-center">
            <a
              href="https://facebook.com/thebravecookiellc"
              target="_blank"
              rel="noopener noreferrer"
              className="text-brandlt hover:text-blacklt transition-colors xdrop-shadow text-2xl mr-10"
              aria-label="visit the brave cookie llc on facebook"
            >
              <FontAwesomeIcon icon={faFacebookF} />
            </a>
          </li>
          <li className="text-center">
            <a
              href="https://instagram.com/thebravecookiellc"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="visit the brave cookie llc on instagram"
              className="text-brandlt hover:text-blacklt transition-colors xdrop-shadow text-2xl mr-10"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </li>
          <li className="text-center">
            <a
              href="mailto:contact@thebravecookie.com"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="email the brave cookie llc"
              className="text-brandlt hover:text-blacklt transition-colors xdrop-shadow text-2xl"
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default SuperNav;
