import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
// import { Redirect } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import {
  SuperNav,
  Navigation,
  MobileNavigation,
  PageTracking,
} from "./components";
import {
  Home,
  Faq,
  Prices,
  Contact,
  GalleryIndex,
  CategoryBaby,
  CategoryBirthday,
  CategoryFood,
  CategoryGraduation,
  CategoryWedding,
  CategoryOther,
} from "./routes";

const routes = [
  { path: "/", name: "Home", Component: Home },
  { path: "/faq", name: "Faq", Component: Faq },
  { path: "/prices", name: "Prices", Component: Prices },
  { path: "/contact", name: "Contact", Component: Contact },
  { path: "/gallery", name: "GalleryIndex", Component: GalleryIndex },

  {
    path: "/gallery/category-baby",
    name: "CategoryBaby",
    Component: CategoryBaby,
  },
  {
    path: "/gallery/category-birthday",
    name: "CategoryBirthday",
    Component: CategoryBirthday,
  },
  {
    path: "/gallery/category-food",
    name: "CategoryFood",
    Component: CategoryFood,
  },
  {
    path: "/gallery/category-graduation",
    name: "CategoryGraduation",
    Component: CategoryGraduation,
  },
  {
    path: "/gallery/category-wedding",
    name: "CategoryWedding",
    Component: CategoryWedding,
  },
  {
    path: "/gallery/category-other",
    name: "CategoryOther",
    Component: CategoryOther,
  },
];

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

ReactDOM.render(
  <div className="min-h-screen">
    <Router>
      <PageTracking />
      <ScrollToTop />
      <SuperNav />
      <div className="mobileOnly">
        <MobileNavigation />
      </div>
      <div className="desktopOnly bg-cream py-2">
        <Navigation />
      </div>
      {routes.map(({ path, Component }) => (
        <Route key={path} exact path={path}>
          {({ match }) => (
            <CSSTransition
              in={match != null}
              timeout={300}
              classNames="page"
              unmountOnExit
            >
              <div className="page">
                <Component />
              </div>
            </CSSTransition>
          )}
        </Route>
      ))}
      {/* <Redirect to="/" /> */}
    </Router>
  </div>,
  document.getElementById("root")
);
