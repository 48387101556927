import React from "react";
import PageFooter from "../components/PageFooter";
import pattern from "../assets/pattern.jpg";

function Faq() {
  return (
    <>
      <div
        className="w-full h-[75px] mb-10 bg-repeat bg-contain"
        style={{ backgroundImage: "url(" + pattern + ")" }}
      ></div>
      <div className="container mx-auto px-4">
        <div className="md:p-8 mb-10 bg-cream rounded-md shadow-md">
          <h1 className="text-center pt-16 md:p-0">FAQ</h1>
          <div className="flex pb-10">
            <div className="flex justify-center flex-col p-4 md:p-0">
              <h3>Where are you located?</h3>
              <p className="mb-5">
                Sylvania, Ohio. Only local orders are accepted (Toledo and
                surrounding areas).
              </p>
              <h3>Do you have a bakery?</h3>
              <p className="mb-5">
                No, I operate under Ohio’s Cottage Food Law. This means I bake
                all orders in my home kitchen.
              </p>
              <h3>Is there a minimum order size?</h3>
              <p className="mb-5">
                One dozen is the minimum order. After one dozen, half dozens can
                be ordered if needed.
              </p>
              <h3>Do you have gluten free options available?</h3>
              <p className="mb-5">
                No, unfortunately I cannot offer any gluten free options at this
                time.
              </p>
              <h3>
                What is the difference between your frosting and royal icing?
              </h3>
              <p className="mb-2">
                The buttercream frosting I use does not completely harden like
                royal icing. It will “crust”, or lightly harden on the top. This
                does make the cookies a lot softer and a lot more fragile. I
                promise the taste is worth having to be a bit more careful!
              </p>
              <p className="mb-5">
                My cookies also look a lot different than the cookies you will
                search for on the internet. Be sure to check out my gallery for
                examples of my previous cookies.
              </p>
              <h3>What forms of payment are available?</h3>
              <p className="mb-5">
                Cash, check, Venmo, or credit/debit cards through my invoice
              </p>
              <h3>Do you offer delivery?</h3>
              <p className="mb-5">
                Delivery can be arranged if you live in the areas surrounding
                Toledo, Ohio.
              </p>
              <h3>Do you ship?</h3>
              <p className="mb-5">
                No, since the cookies are so fragile, they will not ship well.
                Also, per Ohio Cottage Food Law, I can only sell cookies in
                Ohio.
              </p>
              <h3>How much advance notice do you need?</h3>
              <p className="mb-5">
                Custom cookies typically require at least two weeks notice. Busy
                times of the year can book up quickly, so it is best to get on
                the calendar as soon as possible.
              </p>
            </div>
          </div>
        </div>
      </div>

      <PageFooter />
    </>
  );
}

export default Faq;
