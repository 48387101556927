import React, { useEffect } from "react";
import PageFooter from "../components/PageFooter";
import imgone from "../assets/fpo/img/prices.jpg";
import { NavLink } from "react-router-dom";
import pattern from "../assets/pattern.jpg";
import lozad from "lozad";

function Prices() {
  useEffect(() => {
    const { observe, observer } = lozad();
    observe();
    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div
        className="w-full h-[75px] mb-10 bg-repeat bg-contain"
        style={{ backgroundImage: "url(" + pattern + ")" }}
      ></div>
      <div className="container mx-auto px-4">
        <div className="md:p-8 mb-10 bg-cream rounded-md shadow-md">
          <h1 className="text-center pt-16 md:p-0">Prices</h1>
          <div className="flex md:flex-row flex-col-reverse pb-10">
            <div
              className="md:w-5/12 bg-cream h-52 bg-cover bg-no-repeat bg-center md:rounded-md shadow-md lozad"
              data-background-image={imgone}
            ></div>
            <div className="md:w-7/12 md:ml-7 flex justify-center flex-col p-4 md:p-0">
              <h2>Cookie Pricing</h2>
              <h3>Custom Orders start at $24 per dozen</h3>
              <p className="mb-4">
                <em>*minimum requirement is 1 dozen</em>
                <br />
                <em>
                  All cookies come in a bakery box with a clear window. There is
                  an additional fee if you would like cookies individually
                  bagged.
                </em>
              </p>
              <NavLink
                to="/contact"
                className="w-fit mx-auto bg-brand hover:bg-brandlt text-cream hover:text-brand border-2 border-brand hover:border-brandlt font-bold py-2 px-4 rounded-full transition-all shadow-md"
              >
                Order Custom Cookies
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <PageFooter />
    </>
  );
}

export default Prices;
