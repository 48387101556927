import React, { useEffect } from "react";
import lozad from "lozad";
import { NavLink } from "react-router-dom";
import logo from "../assets/logo-opx.png";

function Navigation() {
  useEffect(() => {
    const { observe, observer } = lozad();
    observe();
    return () => observer.disconnect();
  }, []);
  return (
    <>
      <div className="w-full">
        <nav className="container mx-auto">
          <ul className="flex mb-0">
            <li className="w-1/5 flex justify-center items-center">
              <NavLink to="/faq" className="mnlink" activeClassName="selected">
                <h2>FAQ</h2>
                <div className="activeBar"></div>
              </NavLink>
            </li>
            <li className="w-1/5 flex justify-center items-center">
              <NavLink
                to="/gallery"
                className="mnlink"
                activeClassName="selected"
              >
                <h2>Gallery</h2>
                <div className="activeBar"></div>
              </NavLink>
            </li>
            <li className="w-1/5">
              <NavLink to="/" className="flex justify-center">
                <img
                  data-src={logo}
                  alt="the brave cookie llc"
                  style={{ maxWidth: "110px" }}
                  width="110px"
                  height="95px"
                  className="lozad"
                />
              </NavLink>
            </li>
            <li className="w-1/5 flex justify-center items-center">
              <NavLink
                to="/prices"
                className="mnlink"
                activeClassName="selected"
              >
                <h2>Prices</h2>
                <div className="activeBar"></div>
              </NavLink>
            </li>
            <li className="w-1/5 flex justify-center items-center">
              <NavLink
                to="/contact"
                className="w-fit bg-brand hover:bg-brandlt text-cream hover:text-brand border-2 border-brand hover:border-brandlt font-bold py-2 px-4 rounded-full transition-all shadow-md text-center"
                activeClassName="selected"
              >
                Order Now
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default Navigation;
