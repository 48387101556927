import React, { useEffect } from "react";
import PageFooter from "../components/PageFooter";
import banner from "../assets/fpo/img/fpo2.jpg";
import pattern from "../assets/pattern.jpg";
import logo from "../assets/logo-opx.png";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import lozad from "lozad";
import {
  faCookieBite,
  faCircleQuestion,
  faShoppingBag,
} from "@fortawesome/free-solid-svg-icons";

import cimg1 from "../assets/gallery/wedding/wedding17.jpg";
import cimg2 from "../assets/gallery/graduation/graduation05.jpg";
import cimg3 from "../assets/gallery/food/food03.jpg";

function Home() {
  useEffect(() => {
    const { observe, observer } = lozad();
    observe();
    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div
        className="h-[500px] w-full bg-cover bg-no-repeat bg-center flex"
        style={{ backgroundImage: "url(" + banner + ")" }}
      >
        <div
          className="w-full h-full flex flex-col justify-end items-center"
          style={{
            backgroundImage:
              "linear-gradient(rgba(255, 248, 241, 0.6),rgba(255, 248, 241, 0.3),rgba(255, 248, 241, 0.3), rgba(255, 248, 241, 0.3),rgba(255, 248, 241, 0.3),rgba(255, 248, 241, 0.8),rgba(255, 248, 241, 0.8), rgba(255, 248, 241, 0.95))",
          }}
        >
          <img
            data-src={logo}
            alt="the brave cookie llc"
            width="600"
            height="521"
            className="w-72 lozad"
          />
          <NavLink
            to="/contact"
            className="w-fit mx-auto mb-6 bg-brand hover:bg-brandlt text-cream hover:text-brand border-2 border-brand hover:border-brandlt font-bold py-2 px-4 rounded-full transition-all md:mb-8 shadow-md"
          >
            Order Now
          </NavLink>
        </div>
      </div>
      <div
        className="w-full h-[75px] mb-10 bg-repeat bg-contain"
        style={{ backgroundImage: "url(" + pattern + ")" }}
      ></div>

      <div className="container mx-auto flex flex-col md:flex-row md:space-x-4 mb-10 px-4">
        <div
          className="w-full md:w-2/5 h-64 min-h-full bg-cream rounded-tl-md rounded-tr-md md:rounded-md shadow-md text-center bg-center bg-cover lozad"
          data-background-image={cimg1}
        ></div>
        <div className="w-full md:w-3/5 px-4 py-8 md:py-2 flex flex-col justify-center bg-cream rounded-bl-md rounded-br-md md:rounded-md shadow-md text-center">
          <p className="text-3xl text-brand">
            <FontAwesomeIcon icon={faCircleQuestion} />
          </p>
          <h2 className="text-center mb-4">Questions?</h2>
          <NavLink
            to="/faq"
            className="w-fit mx-auto bg-brand hover:bg-brandlt text-cream hover:text-brand border-2 border-brand hover:border-brandlt font-bold py-2 px-4 rounded-full transition-all shadow-md"
          >
            View the FAQ
          </NavLink>
        </div>
      </div>

      <div className="container mx-auto flex flex-col-reverse md:flex-row md:space-x-4 mb-10 px-4">
        <div className="w-full md:w-3/5 px-4 py-8 md:py-2 flex flex-col justify-center bg-cream rounded-bl-md rounded-br-md md:rounded-md shadow-md text-center">
          <p className="text-3xl text-brand">
            <FontAwesomeIcon icon={faCookieBite} />
          </p>
          <h2 className="text-center mb-4">Gallery</h2>
          <NavLink
            to="/gallery"
            className="w-fit mx-auto bg-brand hover:bg-brandlt text-cream hover:text-brand border-2 border-brand hover:border-brandlt font-bold py-2 px-4 rounded-full transition-all shadow-md"
          >
            See Past Designs
          </NavLink>
        </div>
        <div
          className="w-full md:w-2/5 h-64 min-h-full bg-cream rounded-tl-md rounded-tr-md md:rounded-md shadow-md text-center bg-center bg-cover lozad"
          data-background-image={cimg2}
        ></div>
      </div>

      <div className="container mx-auto flex flex-col md:flex-row md:space-x-4 mb-10 px-4">
        <div
          className="w-full md:w-2/5 h-64 min-h-full bg-cream rounded-tl-md rounded-tr-md md:rounded-md shadow-md text-center bg-center bg-cover lozad"
          data-background-image={cimg3}
        ></div>
        <div className="w-full md:w-3/5 px-4 py-8 md:py-2 flex flex-col justify-center bg-cream rounded-bl-md rounded-br-md md:rounded-md shadow-md text-center">
          <p className="text-3xl text-brand">
            <FontAwesomeIcon icon={faShoppingBag} />
          </p>
          <h2 className="text-center mb-4">Order</h2>
          <NavLink
            to="/contact"
            className="w-fit mx-auto bg-brand hover:bg-brandlt text-cream hover:text-brand border-2 border-brand hover:border-brandlt font-bold py-2 px-4 rounded-full transition-all shadow-md"
          >
            Get Cookies Now!
          </NavLink>
        </div>
      </div>

      <PageFooter />
    </>
  );
}

export default Home;
