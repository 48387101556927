import React, { Component } from "react";
import Lightbox from "react-lightbox-gallery";
import PageFooter from "../../components/PageFooter";
import pattern from "../../assets/pattern.jpg";

import galleryImg1 from "../../assets/gallery/baby/baby01.jpg";
import galleryImg2 from "../../assets/gallery/baby/baby02.jpg";
import galleryImg3 from "../../assets/gallery/baby/baby03.jpg";
import galleryImg4 from "../../assets/gallery/baby/baby04.jpg";
import galleryImg5 from "../../assets/gallery/baby/baby05.jpg";
import galleryImg6 from "../../assets/gallery/baby/baby06.jpg";
import galleryImg7 from "../../assets/gallery/baby/baby07.jpg";
import galleryImg8 from "../../assets/gallery/baby/baby08.jpg";
import galleryImg9 from "../../assets/gallery/baby/baby09.jpg";
import galleryImg10 from "../../assets/gallery/baby/baby10.jpg";
import galleryImg11 from "../../assets/gallery/baby/baby11.jpg";
import galleryImg12 from "../../assets/gallery/baby/baby12.jpg";

import { NavLink } from "react-router-dom";

export default class Gallery1 extends Component {
  render() {
    var images = [
      {
        src: galleryImg1,
      },
      {
        src: galleryImg2,
      },
      {
        src: galleryImg3,
      },
      {
        src: galleryImg4,
      },
      {
        src: galleryImg5,
      },
      {
        src: galleryImg6,
      },
      {
        src: galleryImg7,
      },
      {
        src: galleryImg8,
      },
      {
        src: galleryImg9,
      },
      {
        src: galleryImg10,
      },
      {
        src: galleryImg11,
      },
      {
        src: galleryImg12,
      },
    ];
    var settings = {
      columnCount: {
        default: 3,
        mobile: 3,
        tab: 4,
      },
      mode: "dark",
    };
    return (
      <>
        <div
          className="w-full h-[75px] mb-10 bg-repeat bg-contain"
          style={{ backgroundImage: "url(" + pattern + ")" }}
        ></div>
        <div className="container mx-auto px-4">
          <div className="md:p-8 mb-10 pb-10 bg-cream rounded-md shadow-md">
            <h1 className="text-center pt-16 md:p-0 mb-10">Baby Cookies</h1>
            <Lightbox
              images={images}
              settings={settings}
              className="lb-helper"
            />
            <NavLink
              to="/gallery"
              className="w-fit mx-auto bg-brand hover:bg-brandlt text-cream hover:text-brand border-2 border-brand hover:border-brandlt font-bold py-2 px-4 rounded-full transition-all shadow-md"
            >
              Back to Galleries
            </NavLink>
          </div>
        </div>

        <PageFooter />
      </>
    );
  }
}
