import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import imgone from "../assets/fpo/img/fpo1.jpg";
import imgtwo from "../assets/fpo/img/fpo2.jpg";
import imgthree from "../assets/fpo/img/footer2.jpg";
import imgfour from "../assets/fpo/img/fpo4.jpg";
import imgfive from "../assets/fpo/img/footer.jpg";
import mark from "../assets/b-opx.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import lozad from "lozad";

function PageFooter() {
  const currentDate = new Date();
  const currentYear = currentDate.getYear() + 1900;

  useEffect(() => {
    const { observe, observer } = lozad();
    observe();
    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div className="pt-16 pb-6 bg-cream">
        <div className="flex">
          <div className="flex-1 px-2 md:block hidden">
            <div
              className="rounded-md shadow-md 2xl:h-80 md:h-40 h-28 bg-cover bg-no-repeat bg-center lozad"
              data-background-image={imgthree}
            ></div>
          </div>
          <div className="flex-1 px-2">
            <div
              className="rounded-md shadow-md 2xl:h-80 md:h-40 h-28 bg-cover bg-no-repeat bg-center lozad"
              data-background-image={imgtwo}
            ></div>
          </div>
          <div className="flex-1 px-2">
            <div
              className="rounded-md shadow-md 2xl:h-80 md:h-40 h-28 bg-cover bg-no-repeat bg-center lozad"
              data-background-image={imgone}
            ></div>
          </div>
          <div className="flex-1 px-2">
            <div
              className="rounded-md shadow-md 2xl:h-80 md:h-40 h-28 bg-cover bg-no-repeat bg-center lozad"
              data-background-image={imgfour}
            ></div>
          </div>
          <div className="flex-1 px-2 md:block hidden">
            <div
              className="rounded-md shadow-md 2xl:h-80 md:h-40 h-28 bg-cover bg-no-repeat bg-center lozad"
              data-background-image={imgfive}
            ></div>
          </div>
        </div>
      </div>
      <div id="footer" className="bg-cream">
        <footer className="py-5  container mx-auto">
          <div className="flex md:flex-row flex-col-reverse pb-10">
            <div className="md:w-3/12">
              <img
                data-src={mark}
                alt=""
                className="block mx-auto lozad"
                style={{ maxWidth: "110px" }}
                width="110px"
                height="99px"
              />
              <p className="text-center">
                <small>
                  <strong>
                    <em>&copy; The Brave Cookie LLC {currentYear}</em>
                  </strong>
                </small>
              </p>
            </div>
            <div className="md:w-9/12 flex flex-col justify-center">
              <div className="flex flex-col md:flex-row md:border-b-2 border-blacklt mb-2">
                <div className="grow basis-0">
                  <NavLink
                    to="/"
                    className="text-center md:text-right block mb-2 md:mb-0 hover:text-blacklt transition-colors"
                  >
                    <h3>Home</h3>
                  </NavLink>
                </div>
                <div className="grow basis-0">
                  <NavLink
                    to="/faq"
                    className="text-center md:text-right block mb-2 md:mb-0 hover:text-blacklt transition-colors"
                  >
                    <h3>FAQ</h3>
                  </NavLink>
                </div>
                <div className="grow basis-0">
                  <NavLink
                    to="/gallery"
                    className="text-center md:text-right block mb-2 md:mb-0 hover:text-blacklt transition-colors"
                  >
                    <h3>Gallery</h3>
                  </NavLink>
                </div>
                <div className="grow basis-0">
                  <NavLink
                    to="/prices"
                    className="text-center md:text-right block mb-2 md:mb-0 hover:text-blacklt transition-colors"
                  >
                    <h3>Prices</h3>
                  </NavLink>
                </div>
                <div className="grow basis-0">
                  <NavLink
                    to="/contact"
                    className="text-center md:text-right block mb-2 md:mb-0 hover:text-blacklt transition-colors"
                  >
                    <h3>Contact</h3>
                  </NavLink>
                </div>
              </div>
              <div className="hidden md:flex justify-center md:justify-end">
                <a
                  href="https://facebook.com/thebravecookiellc"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="visit the brave cookie llc on facebook"
                  className="hover:text-blacklt transition-colors text-2xl mr-8 md:mr-6"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a
                  href="https://instagram.com/thebravecookiellc"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="visit the brave cookie llc on instagram"
                  className="hover:text-blacklt transition-colors text-2xl mr-8 md:mr-6"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
                <a
                  href="mailto:contact@thebravecookie.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="email the brave cookie llc"
                  className="hover:text-blacklt transition-colors text-2xl"
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}

export default PageFooter;
