import React from "react";
import { NavLink } from "react-router-dom";
import PageFooter from "../../components/PageFooter";
import pattern from "../../assets/pattern.jpg";
import imgone from "../../assets/fpo/img/fpo1.jpg";

import galleryImgBaby from "../../assets/gallery/baby/baby02.jpg";
import galleryImgBirthday from "../../assets/gallery/birthday/birthday07.jpg";
import galleryImgFood from "../../assets/gallery/food/food02.jpg";
import galleryImgGrad from "../../assets/gallery/graduation/graduation05.jpg";
import galleryImgWedding from "../../assets/gallery/wedding/wedding17.jpg";

function GalleryIndex() {
  return (
    <>
      <div
        className="w-full h-[75px] mb-10 bg-repeat bg-contain"
        style={{ backgroundImage: "url(" + pattern + ")" }}
      ></div>
      <div className="container mx-auto px-4">
        <div className="md:p-8 mb-10 bg-cream rounded-md shadow-md">
          <h1 className="text-center pt-16 md:p-0">Gallery</h1>
          <p className="text-center">Select a category to view.</p>
          <div className="flex">
            <ul className="w-full flex flex-wrap">
              <li className="md:w-1/3 w-full">
                <NavLink
                  to="/gallery/category-baby"
                  className="cool-hover bg-brand relative overflow-hidden block h-60 rounded-md m-2"
                >
                  <figure
                    className="h-full flex justify-center overflow-hidden items-center bg-no-repeat bg-center bg-cover"
                    style={{
                      backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),url(" +
                        galleryImgBaby +
                        ")",
                    }}
                  ></figure>
                  <section className="absolute w-full h-full p-4 flex flex-col justify-end items-center top-0 left-0">
                    <h3 className="text-center text-cream">Baby</h3>
                    <button className="w-fit mx-auto bg-brand hover:bg-brandlt text-cream hover:text-brand border-2 border-brand hover:border-brandlt font-bold py-2 px-4 rounded-full transition-all shadow-md">
                      View Gallery
                    </button>
                  </section>
                </NavLink>
              </li>
              <li className="md:w-1/3 w-full">
                <NavLink
                  to="/gallery/category-birthday"
                  className="cool-hover bg-brand relative overflow-hidden block h-60 rounded-md m-2"
                >
                  <figure
                    className="h-full flex justify-center overflow-hidden items-center bg-no-repeat bg-center bg-cover"
                    style={{
                      backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),url(" +
                        galleryImgBirthday +
                        ")",
                    }}
                  ></figure>
                  <section className="absolute w-full h-full p-4 flex flex-col justify-end items-center top-0 left-0">
                    <h3 className="text-center text-cream">Birthday</h3>
                    <button className="w-fit mx-auto bg-brand hover:bg-brandlt text-cream hover:text-brand border-2 border-brand hover:border-brandlt font-bold py-2 px-4 rounded-full transition-all shadow-md">
                      View Gallery
                    </button>
                  </section>
                </NavLink>
              </li>
              <li className="md:w-1/3 w-full">
                <NavLink
                  to="/gallery/category-food"
                  className="cool-hover bg-brand relative overflow-hidden block h-60 rounded-md m-2"
                >
                  <figure
                    className="h-full flex justify-center overflow-hidden items-center bg-no-repeat bg-center bg-cover"
                    style={{
                      backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),url(" +
                        galleryImgFood +
                        ")",
                    }}
                  ></figure>
                  <section className="absolute w-full h-full p-4 flex flex-col justify-end items-center top-0 left-0">
                    <h3 className="text-center text-cream">Food</h3>
                    <button className="w-fit mx-auto bg-brand hover:bg-brandlt text-cream hover:text-brand border-2 border-brand hover:border-brandlt font-bold py-2 px-4 rounded-full transition-all shadow-md">
                      View Gallery
                    </button>
                  </section>
                </NavLink>
              </li>
              <li className="md:w-1/3 w-full">
                <NavLink
                  to="/gallery/category-graduation"
                  className="cool-hover bg-brand relative overflow-hidden block h-60 rounded-md m-2"
                >
                  <figure
                    className="h-full flex justify-center overflow-hidden items-center bg-no-repeat bg-center bg-cover"
                    style={{
                      backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),url(" +
                        galleryImgGrad +
                        ")",
                    }}
                  ></figure>
                  <section className="absolute w-full h-full p-4 flex flex-col justify-end items-center top-0 left-0">
                    <h3 className="text-center text-cream">Graduation</h3>
                    <button className="w-fit mx-auto bg-brand hover:bg-brandlt text-cream hover:text-brand border-2 border-brand hover:border-brandlt font-bold py-2 px-4 rounded-full transition-all shadow-md">
                      View Gallery
                    </button>
                  </section>
                </NavLink>
              </li>
              <li className="md:w-1/3 w-full">
                <NavLink
                  to="/gallery/category-wedding"
                  className="cool-hover bg-brand relative overflow-hidden block h-60 rounded-md m-2"
                >
                  <figure
                    className="h-full flex justify-center overflow-hidden items-center bg-no-repeat bg-center bg-cover"
                    style={{
                      backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),url(" +
                        galleryImgWedding +
                        ")",
                    }}
                  ></figure>
                  <section className="absolute w-full h-full p-4 flex flex-col justify-end items-center top-0 left-0">
                    <h3 className="text-center text-cream">Wedding</h3>
                    <button className="w-fit mx-auto bg-brand hover:bg-brandlt text-cream hover:text-brand border-2 border-brand hover:border-brandlt font-bold py-2 px-4 rounded-full transition-all shadow-md">
                      View Gallery
                    </button>
                  </section>
                </NavLink>
              </li>
              <li className="md:w-1/3 w-full">
                <NavLink
                  to="/gallery/category-other"
                  className="cool-hover bg-brand relative overflow-hidden block h-60 rounded-md m-2"
                >
                  <figure
                    className="h-full flex justify-center overflow-hidden items-center bg-no-repeat bg-center bg-cover"
                    style={{
                      backgroundImage:
                        "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)),url(" +
                        imgone +
                        ")",
                    }}
                  ></figure>
                  <section className="absolute w-full h-full p-4 flex flex-col justify-end items-center top-0 left-0">
                    <h3 className="text-center text-cream">Other</h3>
                    <button className="w-fit mx-auto bg-brand hover:bg-brandlt text-cream hover:text-brand border-2 border-brand hover:border-brandlt font-bold py-2 px-4 rounded-full transition-all shadow-md">
                      View Gallery
                    </button>
                  </section>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <PageFooter />
    </>
  );
}

export default GalleryIndex;
