import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCookieBite } from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/logo-opx.png";

export default class MobileNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuOpen: false,
    };

    this.handleStateChange = this.handleStateChange.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  handleLinkClick(e) {
    this.setState({ menuOpen: false });
  }

  render() {
    return (
      <>
        <div className="bg-cream py-2">
          <div className="block mx-auto w-full" style={{ maxWidth: "110px" }}>
            <NavLink
              to="/"
              exact
              onClick={() => this.handleLinkClick()}
              className="block"
            >
              <img
                src={logo}
                alt="the brave cookie llc"
                style={{ maxWidth: "110px" }}
                width="110px"
                height="95px"
                className="block"
              />
            </NavLink>
          </div>
        </div>
        <Menu
          className="mobinav"
          onStateChange={(state) => this.handleStateChange(state)}
          isOpen={this.state.menuOpen}
          right
        >
          <nav>
            <ul className="mt-20">
              <li className="mb-3">
                <NavLink
                  to="/"
                  exact
                  onClick={() => this.handleLinkClick()}
                  activeClassName="active-page"
                >
                  <h2>
                    Home <FontAwesomeIcon icon={faCookieBite} />
                  </h2>
                </NavLink>
              </li>
              <li className="mb-3">
                <NavLink
                  to="/faq"
                  onClick={() => this.handleLinkClick()}
                  activeClassName="active-page"
                >
                  <h2>
                    FAQ <FontAwesomeIcon icon={faCookieBite} />
                  </h2>
                </NavLink>
              </li>
              <li className="mb-3">
                <NavLink
                  to="/gallery"
                  onClick={() => this.handleLinkClick()}
                  activeClassName="active-page"
                >
                  <h2>
                    Gallery <FontAwesomeIcon icon={faCookieBite} />
                  </h2>
                </NavLink>
              </li>
              <li className="mb-3">
                <NavLink
                  to="/prices"
                  onClick={() => this.handleLinkClick()}
                  activeClassName="active-page"
                >
                  <h2>
                    Prices <FontAwesomeIcon icon={faCookieBite} />
                  </h2>
                </NavLink>
              </li>
              <li className="mb-3">
                <NavLink
                  to="/contact"
                  onClick={() => this.handleLinkClick()}
                  activeClassName="active-page"
                >
                  <h2>
                    Contact <FontAwesomeIcon icon={faCookieBite} />
                  </h2>
                </NavLink>
              </li>
            </ul>
          </nav>
        </Menu>
      </>
    );
  }
}
