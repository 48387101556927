import React from "react";
import PageFooter from "../components/PageFooter";
import pattern from "../assets/pattern.jpg";

function Contact() {
  return (
    <>
      <div
        className="w-full h-[75px] mb-10 bg-repeat bg-contain"
        style={{ backgroundImage: "url(" + pattern + ")" }}
      ></div>
      <div className="container mx-auto px-4">
        <div className="md:p-8 mb-10 bg-cream rounded-md shadow-md">
          <h1 className="text-center pt-16 md:p-0">Order</h1>
          <div className="p-4">
            <h3 className="text-center">
              Due to overwhelming demand we have closed our open request form.
              Thank you for your support!
            </h3>
          </div>
        </div>
      </div>

      <PageFooter />
    </>
  );
}

export default Contact;
